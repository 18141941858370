'use client'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { mq, vw } from '@/styles'

interface IRadioCheckBoxes {
  data: any
}

const getRadioCheckBoxes = (variants) => variants.map((variant, idx) => {
  if(!variant) return
  const { variant: { bigcommerceId:variant_id }, currentVariantId, setCurrentVariant, text } = variant
  return (
    <div key={idx}>
      <input id={idx} onChange={() => { setCurrentVariant(variant.variant) }} type='radio' checked={variant_id === currentVariantId} />
      <span></span>
      <label htmlFor={idx}>{text}</label>
    </div>
  )}
)

export const RadioCheckBoxes = memo<IRadioCheckBoxes>(({ data }) => {
  const InputRadios = useMemo(() => getRadioCheckBoxes(data), [data])

  return (
    <RadioButtonWrapper>
      {InputRadios}
    </RadioButtonWrapper>
  )
})

const RadioButtonWrapper = styled.div`
  span {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.text};
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    display: flex;
    height: ${vw(27, 'mobile')};
    justify-content: center;
    margin-right: ${vw(10, 'mobile')};
    pointer-events: none;
    position: relative;
    user-select: none;
    width: ${vw(25, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(27, 'nexus7')};
      margin-right: ${vw(10, 'nexus7')};
      width: ${vw(25, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(27, 'desktop')};
      margin-right: ${vw(10, 'desktop')};
      width: ${vw(25, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 27px;
      margin-right: 10px;
      width: 25px;
    }

    &:before {
      background-color: ${({ theme }) => theme.colors.white};
      clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
      content: ' ';
      display: none;
      height: ${vw(24, 'mobile')};
      width: ${vw(22, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(24, 'nexus7')};
        width: ${vw(22, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(24, 'desktop')};
        width: ${vw(22, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 24px;
        width: 22px;
      }
    }

    &:after {
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 50%;
      content: ' ';
      height: ${vw(10, 'mobile')};
      position: absolute;
      width: ${vw(10, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(10, 'nexus7')};
        width: ${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(10, 'desktop')};
        width: ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 10px;
        width: 10px;
      }
    }
  }

  label {
    white-space: nowrap;
    cursor: pointer;

  }

  input:not(:checked) + span::before {
    display: block;
  }
`
