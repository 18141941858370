'use client'
import { useCallback, useMemo, useState, useEffect, memo } from 'react'
import type { ChangeEvent, ReactNode } from 'react'
import type { UseFormRegister as TypeRegister, FieldError } from 'react-hook-form'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { mq, vw, Col, hex2Rgba, theme, parsePlaceHolder, getTransition, getP18, getP18InputLabel, getP18InputHasContentLabel, getP16 } from '@/styles'

import type { Paths } from '@/schema'
import { useDropdown } from '../dropdown/useDropdown'
import { Figure } from '../Figure'

const noRegister = () => ({ onChange: null, onBlur: null, ref: null, name: null })
type TypeThemeColors = Paths<typeof theme.colors>
type onChangeType = (event: ChangeEvent<HTMLInputElement>) => void
type TypeOption = {
  value: string
  name: string
  [key:string]:string
}
interface iSelect {
  options: TypeOption[]
  readonly name: string
  readonly label?: string
  placeholder?: string
  defaultValue?: string
  readonly required?: boolean
  readonly disabled?: boolean
  readonly autoComplete?: string
  readonly readOnly?: boolean
  readonly onChange?: onChangeType
  readonly col?: Col
  readonly register?:TypeRegister<any>
  error?: FieldError | any
  setFormValue?: any
  readonly icon?: string | boolean | ReactNode
  readonly className?: string
  readonly placeholderVisible?: boolean
  readonly textColor?: TypeThemeColors
  readonly dropdownTextColor?: TypeThemeColors
  readonly bgColor?: TypeThemeColors
}

interface iLabelProps {
  $hasContent: boolean
  $placeholderVisible: boolean
  $buttonIcon?: boolean
  $textColor?: TypeThemeColors
  $bgColor: TypeThemeColors
}

interface iDivProps {
  $col: Col
  $readOnly?: boolean
}

const getOptionsList = (data, onClickOption, currentValue) => data.map((option, idx) => {
  return (
    <li key={`${option.value}-${idx}`} className={`${currentValue === option.value ? 'current' : ''}${option.store ? ` online-store${data[idx+1]?.store ? '' : ' last-store'}` : ''}`}>
      <button type='button' onClick={() => { onClickOption(option) }} data-dismiss={true}>
        {option.name}
        {option.store && <span className='online-store'>Tienda online</span>}
      </button>
    </li>
  )
})

const arrowIcon = {
  type: 'image' as const,
  alt: 'Arrow icon',
  default: {
    src: '/images/svg/icon-arrow-select.svg',
    size: { width: 8, height: 15 }
  }
}

let TIMEOUT_ID = null
export const Select = memo(({ options, name = 'country_code', defaultValue, label, autoComplete = 'off', placeholder, placeholderVisible = false, required = false, disabled = false, readOnly = null, onChange = null, col, register = noRegister, icon = null, className, textColor = 'text', bgColor = 'background', error, setFormValue, dropdownTextColor= null }:iSelect):JSX.Element => {
  const requireProps = typeof required === 'boolean' ? { required } : required
  const { onChange:registerOnChange, onBlur:registerOnBlur, ref:registerRef } = register(name, { ...requireProps })

  const [fancyValue, setFancyValue] = useState(options.filter(option => option.value === defaultValue)[0]?.name ?? '')
  const [currentOptions, setCurrentOptions] = useState(options)
  const [currentValue, setCurrentValue] = useState(defaultValue ?? '')

  const [triggerRef, dropdownRef, expanded, toggleExpanded] = useDropdown()

  const handleChange = e => {
    registerOnChange && registerOnChange(e)
    onChange && onChange(e)
  }

  const handleBlur = e => {
    registerOnBlur && registerOnBlur(e)
    // setInputValue(e?.target?.value)
    // setFormValue(e?.target?.value)
  }

  console.log('handleChange', handleChange)
  console.log('handleBlur', handleBlur)

  const onClickOption = (option) => {
    setFormValue && setFormValue(name, option.value)
    setCurrentValue(option.value)
    setFancyValue(option.name)
    toggleExpanded()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Options = useMemo(() => getOptionsList(currentOptions, onClickOption, currentValue), [currentOptions, currentValue])

  const filterBySearch = useCallback(async ({ value, valid }) => {
    if (valid) {
      const data = options.filter(option => option.name.toLowerCase().includes(value.toLowerCase()))
      setCurrentOptions(data)
    } else {
      setCurrentOptions(options)
    }
  }, [options])

  const handleSearch = e => {
    const value = e?.target?.value
    setFancyValue(value)

    if(typeof value === 'string') {
      const valid = value.length > 1
      TIMEOUT_ID && clearTimeout(TIMEOUT_ID)
      TIMEOUT_ID = setTimeout(() => filterBySearch({value, valid}), 300)
    }
  }

  const onBlurSearch = () => {
    const value = fancyValue
    let matchOption = null

    if(typeof value === 'string' && value.length > 2) {
      matchOption = options.filter(option => option.name.toLowerCase().includes(value.toLowerCase()))?.[0]
    }

    setFormValue(name, matchOption ? matchOption.value : '')
    setCurrentValue(matchOption? matchOption.value : '')
    setFancyValue(matchOption ? matchOption.name : '')
    setCurrentOptions(options)
  }

  useEffect(() => {
    setCurrentOptions(options)
  }, [options])

  useEffect(() => {
    let matchOption = null

    if (defaultValue) {
      matchOption = options.filter(option => option.value === defaultValue)?.[0]
      setFormValue(name, matchOption ? matchOption.value : '')
      setCurrentValue(matchOption ? matchOption.value : '')
      setFancyValue(matchOption ? matchOption.name : '')
    }
  }, [defaultValue, options, name, setFormValue])

  return (
    <Div ref={triggerRef} $col={col} $readOnly={readOnly}>
      <input type='hidden' name={name} required={required} aria-invalid={!!error}
        {...registerRef && { ref: registerRef }}
        {...defaultValue && { defaultValue }}
      />
      <Label $hasContent={!!fancyValue} $placeholderVisible={placeholderVisible} $textColor={textColor} $bgColor={bgColor} {...className && { className }}>
        <input type='text' name={`${name}_searcher`} disabled={disabled} readOnly={readOnly} placeholder={placeholder ?? label} onChange={handleSearch} onFocus={toggleExpanded} onBlur={onBlurSearch} value={fancyValue} autoComplete={autoComplete} />
        {label && <span>{label}</span>}
        {icon ? icon : <Figure media={arrowIcon} fill={false} lazy={true} className='chevron'/>}
      </Label>
      {!readOnly && <Ul ref={dropdownRef} initial='collapsed' variants={optionsListVariants} animate={expanded ? 'expanded' : 'collapsed'} $textColor={textColor} $dropdownTextColor={dropdownTextColor}>
        {Options}
      </Ul>}
    </Div>
  )
})



const Div = styled.div<iDivProps>`
  padding: 0 ${vw(4.25, 'mobile')};
  position: relative;
  pointer-events: ${({ $readOnly }) => $readOnly ? 'none' : 'auto'};
  width: 100%;

  ${mq.greaterThan('nexus7')} {
    padding: 0 ${vw(4.5, 'tablet')};
  }

  ${ mq.greaterThan('tablet') } {
    padding: 0 ${vw(12.5, 'desktop')};
    width: ${({ $col }) => typeof $col === 'number' ? `${100 * $col / 12}%` : $col };
  }

  ${mq.greaterThan('desktop')} {
    padding: 0 12.5px;
  }

  ${({ $readOnly }) => { if($readOnly) return css`
    .chevron {
      opacity: 0;
    }
  `}}
`

const Ul = styled(motion.ul)<any>`
  background: white;
  box-shadow: 5px 10px 8px #80808038;
  margin-top: 1px;
  max-height: ${vw(20*5.5 + 4.25*6, 'mobile')};
  overflow-y: auto;
  padding: ${vw(8.5, 'mobile')} 0 ${vw(12.5, 'mobile')};
  position: absolute;
  top: ${vw(60, 'mobile')};
  width: calc(100% - ${vw(8.5, 'mobile')});
  z-index: 5;

  ${mq.greaterThan('nexus7')} {
    max-height: ${vw(20*5.5 + 4.25*6, 'tablet')};
    padding: ${vw(8.5, 'tablet')} 0 ${vw(12.5, 'tablet')};
    top: ${vw(60, 'tablet')};
    width: calc(100% - ${vw(8.5, 'tablet')});
  }

  ${mq.greaterThan('tablet')} {
    max-height: ${vw(32*5.5 + 4.25*6, 'desktop')};
    padding: ${vw(8.5, 'desktop')} 0 ${vw(12.5, 'desktop')};
    top: ${vw(60, 'desktop')};
    width: calc(100% - ${vw(25, 'desktop')});
  }

  ${mq.greaterThan('desktop')} {
    max-height: 201.5px;
    padding: 8.5px 0 12.5px;
    top: 60px;
    width: calc(100% - 25px);
  }

  li {
    margin-bottom: ${vw(4.25, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(4.25, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(4.25, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 4.25px;
    }

    &.online-store {
      &.last-store {
        margin-bottom: ${vw(8.25, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          margin-bottom: ${vw(8.25, 'tablet')};
        }

        ${mq.greaterThan('tablet')} {
          margin-bottom: ${vw(8.25, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin-bottom: 8.25px;
        }

        + li {
          border-top: 1px solid ${({ theme, $textColor }) => hex2Rgba(theme.colors[$textColor], .4)};
          margin-top: ${vw(8.25, 'mobile')};
          padding-top: ${vw(8.25, 'mobile')};

          ${mq.greaterThan('nexus7')} {
            margin-top: ${vw(8.25, 'tablet')};
            padding-top: ${vw(8.25, 'tablet')};
          }

          ${mq.greaterThan('tablet')} {
            margin-top: ${vw(8.25, 'desktop')};
            padding-top: ${vw(8.25, 'desktop')};
          }

          ${mq.greaterThan('desktop')} {
            margin-top: 8.25px;
            padding-top: 8.25px;
          }
        }
      }
    }

  }

  button {
    ${getP16()}
    align-items: center;
    color: ${({ theme, $textColor, $dropdownTextColor }) => $dropdownTextColor ? theme.colors[$dropdownTextColor] : theme.colors[$textColor]};
    display: flex;
    justify-content: space-between;
    opacity: .4;
    overflow-x: hidden;
    padding: ${vw(2, 'mobile')} ${vw(4.25, 'mobile')};
    text-overflow: ellipsis;
    transition: 300ms opacity ease-out;
    white-space: nowrap;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(2, 'tablet')} ${vw(4.25, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      padding: ${vw(5, 'desktop')} ${vw(12.5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 5px 12.5px;
    }

    &:hover {
      opacity: 1;
    }

    .online-store {
      opacity: .7;
    }
  }
`

const hasContentState = () => css`
  ${getP18InputHasContentLabel()}
  opacity: 1;
  transform: translateY(-${vw(35, 'mobile')});

  ${mq.greaterThan('tablet')} {
    transform: translateY(-${vw(35, 'desktop')});
  }

  ${mq.greaterThan('desktop')} {
    transform: translateY(-35px);
  }
`

const Label = styled.label<iLabelProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${vw(20, 'mobile')};
  position: relative;
  width: 100%;

  ${mq.greaterThan('nexus7')} {
    margin-bottom: ${vw(20, 'tablet')};
  }

  ${ mq.greaterThan('tablet') } {
    margin-bottom: ${vw(30, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    margin-bottom: 30px;
  }

  > input {
    ${getP18()}
    background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
    border-bottom: 1px solid;
    border-color: ${({ theme, $textColor }) => hex2Rgba(theme.colors[$textColor], .4)};
    color: ${({ theme, $textColor }) => $textColor ? theme.colors[$textColor] : theme.colors.text};
    height: ${vw(60, 'mobile')};
    padding: ${vw(25, 'mobile')} ${vw(4.25, 'mobile')} ${vw(12, 'mobile')};
    transition: 300ms border-color ease-out;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      height: ${vw(60, 'tablet')};
      padding: ${vw(25, 'tablet')} ${vw(4.25, 'tablet')} ${vw(12, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(60, 'desktop')};
      padding: ${vw(25, 'desktop')} ${vw(12.5, 'desktop')} ${vw(12, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 60px;
      padding: 25px 12.5 12px;
    }

    ${({ theme, $placeholderVisible, $textColor }) => parsePlaceHolder(`
      color: ${$placeholderVisible ? theme.colors[$textColor] : 'rgba(0, 0, 0 ,0)'};
      opacity: ${$placeholderVisible ? .4 : 0};
    `)}

    ${({ $buttonIcon }) => $buttonIcon && css`
      padding-right: ${vw(40, 'mobile')};

      ${mq.greaterThan('tablet')} {
        padding-right: ${vw(40, 'desktop')};
      }

      > button {
        align-items: center;
        background: transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        bottom: ${vw(5, 'mobile')};
        display: flex;
        height: ${vw(21, 'mobile')};
        position: absolute;
        right: ${vw(4.25, 'mobile')};
        user-select: none;

        ${mq.greaterThan('nexus7')} {
          bottom: ${vw(5, 'tablet')};
          height: ${vw(21, 'tablet')};
          right: ${vw(12.5, 'tablet')};
        }

        ${mq.greaterThan('tablet')} {
          bottom: ${vw(5, 'desktop')};
          height: ${vw(21, 'desktop')};
          right: ${vw(12.5, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          bottom: 5px;
          height: 21px;
          right: 12.5px;
        }
      }
    `}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${({ theme, $textColor }) => theme.colors[$textColor]};
      -webkit-box-shadow: 0 0 0 500px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      box-shadow: 0 0 0 100px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      color: inherit;
    }

    ${({ theme, $placeholderVisible, $textColor }) => parsePlaceHolder(`
      opacity: ${$placeholderVisible ? .4 : 0};
      color: ${$placeholderVisible ? theme.colors[$textColor] : 'rgba(0, 0, 0 ,0)'};
    `)}

    &:focus {
      border-color: ${({ theme, $textColor }) => hex2Rgba(theme.colors[$textColor], 1)};

      + span {
        ${hasContentState()}
      }
    }

    &[readonly] {
      opacity: .3;

      + span {
        opacity: .3;
      }
    }
  }

  > span {
    ${({ $placeholderVisible }) => $placeholderVisible ? hasContentState() : getP18InputLabel()}
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    bottom: ${vw(12, 'mobile')};
    color: ${({ theme, $textColor }) => theme.colors[$textColor]};
    opacity: 1;
    position: absolute;
    transform: translateY(-${vw(35, 'mobile')});
    transition: 300ms font-size ease, 300ms transform ease, 300ms opacity ease;
    white-space: nowrap;

    ${mq.greaterThan('nexus7')} {
      bottom: ${vw(12, 'tablet')};
      transform: translateY(-${vw(35, 'tablet')});
    }

    ${mq.greaterThan('tablet')} {
      bottom: ${vw(12, 'desktop')};
      transform: translateY(-${vw(35, 'desktop')});
    }

    ${mq.greaterThan('desktop')} {
      bottom: 12px;
      transform: translateY(-35px);
    }
  }

  figure {
    &.chevron {
      bottom: ${vw(21, 'mobile')};
      height: ${vw(24, 'mobile')};
      pointer-events: none;
      position: absolute;
      right: ${vw(20, 'mobile')};
      width: ${vw(12, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        bottom: ${vw(21, 'tablet')};
        height: ${vw(24, 'tablet')};
        right: ${vw(20, 'tablet')};
        width: ${vw(12, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        bottom: ${vw(21, 'desktop')};
        height: ${vw(24, 'desktop')};
        right: ${vw(20, 'desktop')};
        width: ${vw(12, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        bottom: 21px;
        height: 24px;
        right: 20px;
        width: 12px;
      }

      img,
      svg {
        height: 100%;
        object-fit: contain;
        transition: 300ms transform ease-out;
        width: 100%;
      }
    }
  }
`

const optionsListVariants = {
  collapsed: {
    height: '0',
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
    transition: { ...getTransition() }
  },
  expanded: {
    height: 'auto',
    opacity: 1,
    display: 'block',
    transition: { ...getTransition() }
  }
}
