'use client'
import { useState } from 'react'
import type { ChangeEvent } from 'react'
import type { UseFormRegister as TypeRegister, FieldError } from 'react-hook-form'
import styled from 'styled-components'
import { noRegister, type TypeThemeColors } from './Input'
import { mq, vw, getP16, type Col, createUnderline } from '@/styles'
type onChangeType = (event: ChangeEvent<HTMLInputElement>) => void
interface iCheckbox {
  readonly name: string,
  readonly label: string,
  defaultChecked?: boolean,
  readonly required?: boolean,
  readonly disabled?: boolean,
  readonly readOnly?: boolean | null,
  readonly onChange?: onChangeType,
  readonly col?: Col
  readonly register?: TypeRegister<any>
  error?: FieldError | any,
  readonly textColor?: TypeThemeColors
  readonly bgColor?: TypeThemeColors
  readonly switchAppearance?: boolean,
  readonly linkText?: React.ReactNode
}
interface iLabelProps {
  $col: Col
  $textColor?: TypeThemeColors
  $bgColor: TypeThemeColors
  $checked: boolean
}

export const Checkbox = ({ name, label, defaultChecked = false, required = false, disabled = false, readOnly = null, onChange = null, col = 12, register = noRegister, textColor = 'text', bgColor = 'background', switchAppearance = false, error, linkText  }: iCheckbox): JSX.Element => {
  const requireProps = typeof required === 'boolean' ? { required } : required
  const { onChange: registerOnChange, name: registerName, ref: registerRef } = register(name, { ...requireProps })
  const [checked, setChecked] = useState(defaultChecked)
  const handleChange = e => {col
    registerOnChange && registerOnChange(e)
    onChange && onChange(e)
    setChecked(e.target.checked)
  }
  return (
    <Label $col={col} $bgColor={bgColor} $textColor={textColor} $checked={checked}>
      <input {...registerRef && { ref: registerRef }} type='checkbox' name={registerName ?? name} required={required} readOnly={readOnly} disabled={disabled} onChange={handleChange} aria-invalid={!!error} {...defaultChecked && { defaultChecked: checked }}
      />
      {switchAppearance ? <Switch $checked={checked} /> : <TickBox $checked={checked} />}
      <span>{label} {linkText && linkText} </span>
    </Label>
  )
}

const Label = styled.label<iLabelProps>`
  align-items: flex-start;
  color: ${({ theme, $textColor }) => theme.colors[$textColor]};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin:  ${vw(30, 'mobile')} auto;
  padding-top: ${vw(4.25, 'mobile')};
  position: relative;
  width: 90%;

  ${mq.greaterThan('nexus7')} {
    margin:  ${vw(30, 'tablet')} auto;
    padding-top: ${vw(4.25, 'nexus7')};
  }
  ${mq.greaterThan('tablet') } {
    margin: ${vw(40, 'desktop')} auto;
    padding-top: ${vw(12.5, 'desktop')};
  }
  ${mq.greaterThan('desktop')} {
    margin:  40px auto;
    padding-top: 12px ;
  }
  > div {
    border-color: ${({ theme }) => theme.colors['nigth_purple']};
  }
  input {
    opacity: 0;
    position: absolute;
    user-select: none;
    visibility: hidden;
  }
  &:hover {
    input + div {
      opacity: .8;
    }
  }
  span {
    ${getP16()}
    display: inline-block;
    margin-top: 3px;
    user-select: none;
  }
  a {
    ${createUnderline({ color: 'white', reverse: true})}
    ${getP16()}
    user-select: auto;
    pointer-events: all;
  }
`
const TickBox = styled.div<{ $checked: boolean }>`
  background-color: ${({ theme, $checked }) => $checked ? theme.colors['neon_purple'] : 'transparent'};
  border: 1px solid;
  flex-shrink: 0;
  height: 16px;
  margin-right: ${vw(10, 'mobile')};
  margin-top: 5px;
  position: relative;
  transition: 250ms background-color linear, 250ms opacity linear;
  user-select: none;
  width: 16px;
  ${mq.greaterThan('nexus7')} {
    margin-right: ${vw(10, 'tablet')};
  }
  ${mq.greaterThan('tablet')} {
    margin-right: ${vw(10, 'desktop')};
  }
  ${mq.greaterThan('desktop')} {
    margin-right: 10px;
  }
`
const Switch = styled.div<{ $checked: boolean }>`
  align-items: center;
  background: ${({ theme, $checked }) => theme.colors[$checked ? 'neon_purple' : 'grey']};
  border-radius: 9.5px;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 21px;
  margin-right: ${vw(10, 'nexus7')};
  padding: 0 1px;
  position: relative;
  transition: 250ms background-color linear, 250ms opacity linear;
  width: 33px;
  ${mq.greaterThan('nexus7')} {
    margin-right: ${vw(10, 'tablet')};
  }
  ${mq.greaterThan('tablet')} {
    margin-right: ${vw(10, 'desktop')};
  }
  ${mq.greaterThan('desktop')} {
    margin-right: 10px;
  }
  &:after {
    background: var(--color-white);
    border-radius: 50%;
    content: '';
    height: 15px;
    margin-left: ${({ $checked }) => $checked ? '15px' : 0};
    transition: 250ms margin-left linear;
    width: 15px;
  }
`