'use client'
import { useMemo, useState, useRef, isValidElement, useEffect } from 'react'
import type { ChangeEvent, ReactNode } from 'react'
import type { UseFormRegister as TypeRegister, FieldError, RegisterOptions } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { mq, vw, hex2Rgba, parsePlaceHolder, theme, type Col ,getP18, getP18InputLabel, getP18InputHasContentLabel, font13_13, font15_15 } from '@/styles'
import type { Paths } from '@/schema'
import { Figure } from '../Figure'

type onChangeType = (event: ChangeEvent<HTMLInputElement>) => void

export type TypeThemeColors = Paths<typeof theme.colors>
interface iInput {
  readonly type?: string
  readonly name: string
  readonly label?: string
  placeholder?: string
  defaultValue?: string | number | string[] | null
  readonly required?: boolean | RegisterOptions
  readonly disabled?: boolean
  readonly autoComplete?: string
  readonly readOnly?: boolean
  readonly onChange?: onChangeType
  readonly col?: Col
  readonly register?:TypeRegister<any>
  error?: FieldError | any
  readonly icon?: string | boolean | ReactNode
  readonly className?: string
  readonly placeholderVisible?: boolean
  readonly textColor?: TypeThemeColors
  readonly bgColor?: TypeThemeColors
  readonly autoFocus?: boolean
}

interface iLabelProps {
  $hasContent: boolean
  $hidden: boolean
  $hasError?: boolean
  $col: Col
  $placeholderVisible: boolean
  $buttonIcon: boolean
  $textColor?: TypeThemeColors
  $bgColor: TypeThemeColors,
  $password: boolean
}

const hasContentState = () => css`
  ${getP18InputHasContentLabel()}
  opacity: 1;
  transform: translateY(-${vw(25, 'mobile')});

  ${mq.greaterThan('tablet')} {
    transform: translateY(-${vw(30, 'desktop')});
  }

  ${mq.greaterThan('desktop')} {
    transform: translateY(-30px);
  }
`

const mediaPasswordIcon1 = {
  type: 'image' as const,
  alt: 'Password eye icon',
  s: {
    src: '/images/svg/i--password1.svg',
    size: { width: 18, height: 18 }
  }
}
const mediaPasswordIcon2 = {
  type: 'image' as const,
  alt: 'Password eye icon',
  s: {
    src: '/images/svg/i--password2.svg',
    size: { width: 18, height: 18 }
  }
}

// aria-invalid https://react-hook-form.com/advanced-usage
const getIcon = (icon, type, setInputType, setIconType, iconType) => {
  if(!icon) return false
  if(typeof icon === 'boolean' || typeof icon === 'string') {
    const mediaIcon = iconType === 'password' ? mediaPasswordIcon1 : mediaPasswordIcon2
    if(type === 'password') {
      const Icon = <button key='icon' type='button' className='btn--toggle-password' onClick={(e) => {
        e.preventDefault()
        setInputType(prevType => prevType === 'password' ? 'text' : 'password')
        setIconType(prevType => prevType === 'password' ? 'text' : 'password')
      }}><Figure media={mediaIcon}/></button>
      return [Icon, true]
    } else if(type === 'search') {
      // Search and clear action
    }
  } else if (isValidElement(icon)) {

  }
}

export const noRegister = () => ({ onChange: null, onBlur: null, ref: null, name: null })
// Attributes https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input

export const Input = ({ type = 'text', name = '', defaultValue = '', label, autoComplete, placeholder, placeholderVisible = false, required = false, disabled = false, readOnly = null, onChange = null, col, register = noRegister, icon, className, textColor = 'text', bgColor = 'background', error, autoFocus = false }:iInput):JSX.Element => {
  const requireProps:RegisterOptions = typeof required === 'boolean' ? { required } : required
  const { onChange:registerOnChange, onBlur:registerOnBlur, name:registerName, ref:registerRef } = register(name, requireProps)
  const ref = useRef<HTMLInputElement | null>(null)

  const [inputType, setInputType] = useState(type)
  const [inputValue, setInputValue] = useState(defaultValue)
  const [iconType, setIconType] = useState('password')
  const isPasswrord = useMemo(() => type === 'password' && iconType === 'password', [type, iconType])
  const handleChange = e => {
    registerOnChange && registerOnChange(e)
    onChange && onChange(e)
    setInputValue(e?.target?.value)
  }

  const handleBlur = e => {
    registerOnBlur && registerOnBlur(e)
    setInputValue(e?.target?.value)
  }

  const ButtonIcon = useMemo(() => getIcon(icon, type, setInputType, setIconType, iconType), [icon, type, setIconType, iconType])

  useEffect(() => {
    if(error) console.log(`input ${name} error`, error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (autoFocus && ref.current) ref.current.focus()
  }, [autoFocus])

  return (
    <Label $hasContent={!!defaultValue || !!inputValue} $hidden={inputType === 'hidden'} $col={col} $buttonIcon={!!ButtonIcon} $placeholderVisible={placeholderVisible} $textColor={textColor} $bgColor={bgColor} $hasError={!!error} $password={isPasswrord}  {...className && { className }}>
      <input type={inputType} name={registerName ?? name} disabled={disabled} readOnly={readOnly} placeholder={placeholder ?? label} onChange={handleChange} onBlur={handleBlur} aria-invalid={!!error}
        {...typeof required === 'boolean' && { required }}
        ref={(e) => {
          ref.current = e
          registerRef && registerRef(e)
        }}
        {...autoComplete && { autoComplete }}
        {...defaultValue && { defaultValue }}
        {...(!defaultValue && !registerRef && inputValue && { value: inputValue })}
      />
      {label && <span>{label}</span>}
      {ButtonIcon && ButtonIcon}
      { /* COMMENTED REQUESTED BY LLUIS FOR AICAM PROJECT */}
      {/* {error?.message && <span role='alert'>{error.message}</span>} */}
    </Label>
  )
}


const Label = styled.label<iLabelProps>`
  display: flex;
  flex-direction: column;
  margin: 0 auto ${vw(20, 'mobile')};
  padding: 0 ${ vw(4.25, 'mobile') };
  position: relative;
  width: 95%;

  ${mq.greaterThan('nexus7')} {
    margin: 0 auto ${vw(20, 'tablet')};
    padding: 0 ${ vw(4.25, 'tablet') };
  }

  ${mq.greaterThan('tablet') } {
    margin: 0 auto ${vw(30, 'desktop')};
    padding: 0 ${vw(12.5, 'desktop')};
    width: ${({ $col }) => typeof $col === 'number' ? `${95 * $col / 12}%` : $col };
  }

  ${mq.greaterThan('desktop')} {
    margin: 0 auto 30px;
    padding: 0 12.5px;
  }

  ${({ $hidden }) => $hidden && css`
    opacity: 0;
    pointer-events: none;
    position: absolute;
    user-select: none;
    visibility: hidden;
  `}

  > input {
    ${getP18()}
    background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
    border-bottom: 1px solid;
    border-color: ${({ theme, $textColor, $hasError }) => hex2Rgba(theme.colors[$hasError ? 'input_error' : $textColor], $hasError ? 1 : .4)};
    color: inherit;
    height: ${vw(60, 'mobile')};
    letter-spacing: ${({ $password }) => $password  ? '5px' : '.6px'};
    padding: ${vw(25, 'mobile')} 0 ${vw(12, 'mobile')};
    transition: 300ms border-color ease-out;
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      height: ${vw(60, 'nexus7')};
      padding: ${vw(25, 'nexus7')} 0 ${vw(12, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(60, 'desktop')};
      padding: ${vw(25, 'desktop')} 0 ${vw(12, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 60px;
      padding: 25px 0 12px;
    }

    &[readonly],
    &[disabled] {
      cursor: auto;
      pointer-events: none;
    }

    &[readonly] {
      opacity: .3;

      + span {
        opacity: .3;
      }
    }

    &[disabled] {
      opacity: .2;

      + span {
        opacity: .2;
      }
    }

    ${({ $buttonIcon }) => $buttonIcon && css`
      padding-right: ${vw(40, 'iphone6')};

      ${mq.greaterThan('tablet')} {
        padding-right: ${vw(40, 'desktop')};
      }

      > button {
        align-items: center;
        background: transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        bottom: ${vw(5, 'mobile')};
        display: flex;
        height: ${vw(21, 'mobile')};
        position: absolute;
        right: ${vw(4.25, 'mobile')};
        user-select: none;

        ${mq.greaterThan('nexus7')} {
          bottom: ${vw(5, 'nexus7')};
          height: ${vw(21, 'nexus7')};
          right: ${vw(4.25, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          bottom: ${vw(5, 'desktop')};
          height: ${vw(21, 'desktop')};
          right: ${vw(12.5, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          bottom: 5px;
          height: 21px;
          right: 12.5px;
        }
      }
    `}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${({ theme, $textColor }) => theme.colors[$textColor]};
      -webkit-box-shadow: 0 0 0 500px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      box-shadow: 0 0 0 100px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      color: inherit;
    }

    ${({ theme, $placeholderVisible, $textColor }) => parsePlaceHolder(`
      opacity: ${$placeholderVisible ? .4 : 0};
      color: ${$placeholderVisible ? theme.colors[$textColor] : 'rgba(0, 0, 0 ,0)'};
    `)}

    &:focus {
      border-color: ${({ theme, $hasError }) => $hasError ? theme.colors['input_error'] : theme.colors.text};

      + span {
        ${hasContentState()}
        color: var(--color-white);
      }
    }
  }

  > span {
    /* FLOATING LABEL */
    &:first-of-type {
      ${({ $placeholderVisible }) => $placeholderVisible ? hasContentState() : getP18InputLabel()}
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      bottom: ${vw(12, 'mobile')};
      color: ${({ theme, $textColor }) => theme.colors[$textColor]};
      opacity: .4;
      position: absolute;
      transition: 300ms font-size ease, 300ms transform ease, 300ms opacity ease;
      white-space: nowrap;

      ${mq.greaterThan('nexus7')} {
        bottom: ${vw(12, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        bottom: ${vw(12, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        bottom: 12px;
      }

      ${({ $hasContent }) => $hasContent ? css`${hasContentState()}` : ''}
    }

    /* ERROR MESSAGE */
    &[role='alert'] {
      ${font13_13(true, 400)}
      color: ${({ theme }) => theme.colors['input_error']};
      pointer-events: none;
      position: absolute;
      top: ${vw(62, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        top: ${vw(62, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        top: ${vw(65, 'desktop')};
      }

      ${mq.greaterThan('tablet')} {
        ${font15_15(true, 400)}
      }

      ${mq.greaterThan('desktop-xs')} {
        ${font13_13(false, 400)}
      }


      ${mq.greaterThan('desktop')} {
        top: 65px;
      }
    }
  }

  figure {
    height: ${vw(15, 'mobile')};
    position: absolute;
    right: ${vw(17, 'mobile')};
    top: 40%;
    width: ${vw(23, 'mobile')};

    img {
      height: 100%;
      width: 100%;
    }

    ${mq.greaterThan('nexus7')} {
      height: ${vw(15, 'nexus7')};
      right: ${vw(17, 'nexus7')};
      width: ${vw(23, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(15, 'desktop')};
      right: ${vw(17, 'desktop')};
      width: ${vw(23, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 15px;
      right: 17px;
      width: 23px;
    }
  }
`
