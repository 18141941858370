'use client'
import { type FC, type Dispatch, useState, useCallback } from 'react'
import styled from 'styled-components'
import { font15_22, hex2Rgba, mq } from '@/styles'
import dynamic from 'next/dynamic'
const ReactQuill = dynamic(() => import('react-quill'), { ssr: false })
import 'react-quill/dist/quill.snow.css'
import { useMount, useTranslator } from '@/hooks'

type Props = {
  title: string
  gender: string
  setTextPrompt?: Dispatch<string>
  setIsDisabled?: Dispatch<boolean>
  defaultValue?: string
}

function slugifyWithUnderscores(text) {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '_')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '_')       // Replace multiple - with single -
}

function stripHtmlTags(inputString) {
  // Regex to identify HTML tags
  const regex = /<[^>]*>/g
  // Replace all occurrences of HTML tags with an empty string
  const outputString = inputString.replace(regex, '')
  return outputString
}


function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

function checkAndProcessBoldTag(text, fixedWord) {
  // Define the regular expression to find content inside <b> tags
  const regex = /<strong>(.*?)<\/strong>/gi
  let match = regex.exec(text)
  let updatedText = text
  let fixedWordFound = false

  // Process all <b> tags found in the text
  while (match != null) {
    // Check if the content inside the <b> tag is the fixedWord
    if (match[1] === fixedWord) {
      fixedWordFound = true
    } else {
      // If content is not the fixedWord, remove the <b> tag and its content
      updatedText = updatedText.replace(match[0], '')
    }
    // Continue to the next match
    match = regex.exec(text)
  }

  // Determine the return value based on the presence and content of <b> tags
  if (fixedWordFound) {
    return {
      reviewedText: text,  // original text is returned unmodified
      fixedWordInTag: true
    }
  } else if (updatedText === text) {
    // No <b> tags were modified, means no <b> tags found
    return {
      reviewedText: text,
      fixedWordInTag: false
    }
  } else {
    // <b> tags were found but none contained the fixedWord
    return {
      reviewedText: updatedText,  // text with <b> tags and their contents removed
      fixedWordInTag: false
    }
  }
}


let word = null
function insertFixedWord() {
  const getCursorPosition = (quill) => quill.getSelection().index
  this.quill.insertText(getCursorPosition(this.quill), ' ')
  const tag = `<b>${word}</b> ·`
  this.quill.clipboard.dangerouslyPasteHTML(getCursorPosition(this.quill), tag)
  this.quill.deleteText(getCursorPosition(this.quill) - 1, 1)
  this.quill.setSelection(this.quill.getLength(), 0)
}


const CustomFixedWord = ({ fixedWord }) => (
  <>
    <span>{fixedWord}</span>
  </>
)

export const QuillToolbar = ({ fixedWord, disabled }) => {
  const { t } = useTranslator()

  return (
    <div id='toolbar'>
      <span className='ql-formats'>
        <button className='ql-insertFixedWord' disabled={disabled}>
          <CustomFixedWord fixedWord={`${t('commons.actions.add')} ${fixedWord}`}/>
        </button>
      </span>
    </div>
  )
}

const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      insertFixedWord
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  },
  // clipboard: {
  //   matchVisual: false
  // }
}

const formats = [
  'bold', 'code-block' // Ensure Quill knows to accept bold formatting
]

// const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), [])
// function findDuplicatesSorted(indexes = [], newIndexes) {
//   if (!Array.isArray(indexes) || !Array.isArray(newIndexes)) return []
//   // Convert newIndexes to a Set for faster lookup
//   const newIndexSet = new Set(newIndexes)

//   // Filter indexes to get only those numbers that also exist in newIndexes
//   const duplicates = indexes.filter(item => newIndexSet.has(item))

//   // Remove duplicates within the duplicates array by converting it to a Set and back to an Array
//   const uniqueDuplicates = Array.from(new Set(duplicates))

//   // Sort the resulting array of duplicates in ascending order
//   uniqueDuplicates.sort((a, b) => a - b)

//   return uniqueDuplicates
// }

// function removeItemsByIndex(arr, positionsToRemove) {
//   // Convert positionsToRemove into a Set for quick lookup
//   const positionsSet = new Set(positionsToRemove)
//   // Filter the array to include elements whose index is not in positionsSet
//   const filteredArray = arr.filter((_item, index) => !positionsSet.has(index))
//   return filteredArray
// }



export function replaceString(textString: string, fixedString: string, commandValue: string): string {
  const regex = new RegExp(escapeRegExp(fixedString), 'g')
  return textString.replace(regex, commandValue)
}

const getDefaultValue = (defaultValue, gender, fixedString) => defaultValue ? replaceString(defaultValue, `ohwx ${gender}`, `<b>${fixedString}</b>`) : `A professional portrait of <b>${fixedString}</b>, `

export const TextAreaFixedWord: FC<Props> = ({ title = 'Human', gender, setTextPrompt, defaultValue, setIsDisabled }) => {
  const fixedString = slugifyWithUnderscores(title).toUpperCase()
  word = fixedString
  const isMount = useMount()
  const [value, setValue] = useState(getDefaultValue(defaultValue, gender, fixedString))
  const [fixedStringPresent, setFixedStringPresent] = useState(true)

  const handleChange = useCallback((value, _delta, _source) => {

    const newVal = checkAndProcessBoldTag(value, fixedString)
    setValue(newVal?.reviewedText)
    setFixedStringPresent(newVal?.fixedWordInTag)
    setIsDisabled(!newVal?.fixedWordInTag)

    const parsedValue = replaceString(newVal?.reviewedText, `<strong>${fixedString}</strong>`, `ohwx ${gender}`)
    setTextPrompt(stripHtmlTags(parsedValue))
  }, [fixedString, setIsDisabled, gender, setTextPrompt])

  return (
    isMount && <Div>
      <div className='text-editor'>
        <ReactQuill theme='snow' value={value} onChange={handleChange} placeholder='Start typing...' modules={modules} formats={formats} preserveWhitespace={true} />
        <QuillToolbar fixedWord={fixedString} disabled={fixedStringPresent} />
      </div>
    </Div>
  )
}


const Div = styled.div`
  display: flex;
  margin-top: 10px;
  min-height: 250px;
  position: relative;
  width: 100%;

  .text-editor,
  .quill {
    width: 100%;
  }

  .text-display,
  .highlight,
  textarea {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .ql-editor strong {
    background-color: var(--color-neon-purple);
    border-radius: 25px;
    color: var(--color-white);
    display: inline-block;
    height: 25px;
    padding: 1px 10px;
  }

  .ql-container {
    border-color: ${hex2Rgba('#ffffff', .4)};
    border-radius: 10px;
    min-height: 160px;
  }

  .ql-editor.ql-blank {
    &:before {
      color: inherit;
    }
  }

  .ql-toolbar {
    border: 0;
    text-align: center;

    button.ql-insertFixedWord {
      align-items: center;
      border: 1px solid white;
      border-radius: 15px;
      display: flex;
      height: 30px;
      justify-content: center;
      padding: 2px 10px;
      transition: 200ms border-color linear;
      user-select: none;
      white-space: nowrap;
      width: fit-content;

      span {
        display: inline-block;
        color: inherit;
        padding: 0 2px;
        transition: 200ms color linear;
      }

      &:hover {
        border-color: var(--color-neon-purple);
        color: var(--color-neon-purple);
      }

      &:disabled {
        border-color: ${hex2Rgba('#ffffff', .4)};
        color: ${hex2Rgba('#ffffff', .4)};
        pointer-events: none;
      }
    }
  }

  .ql-formats {
    background: var(--color-background);
    margin-left: auto;
    margin-top: -35px;
    position: relative;
    z-index: 2;
  }

  .text-display,
  textarea {
    ${font15_22(true)}
    background-color: transparent;
    border: 0;
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: 15px;
    color: inherit;
    font-family: var(--font-satoshi);
    height: 100%;
    left: 0;
    min-height: 150px;
    overflow-y: auto;
    padding: 16px;
    position: absolute;
    top: 0;
    width: 100%;

    ${mq.greaterThan('tablet')} {
      ${font15_22(false)}
    }
  }

  .text-display {
    pointer-events: none; /* Makes the div not clickable */
    z-index: 3; /* Ensure the div is above the textarea */
  }

  textarea {
    resize: none; /* Optional: disable resizing */
    z-index: 2; /* Positioned above the styled div */
  }

  .highlight {
    color: var(--color-young-cherry);
    display: inline;
    position: relative;
    z-index: 5;
  }
`
